@font-face {
    font-display: "swap";
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-display: "swap";
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-display: "swap";
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-display: "swap";
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-display: "swap";
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-display: "swap";
    font-family: "FiraSans";
    src: url("../assets/fonts/FiraSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-display: "swap";
    font-family: "Pacifico";
    src: url("../assets/fonts/Pacifico-Regular.ttf") format("truetype");
}

$whiteCol: #fff;
$blackCol: #1a1a1a;
$darkCol: #696969;
$darkGrayCol: #cfcfcf;
$grayCol: #f1f1f1;
$greenCol: #5fb961;
$pinkCol: #f9650b;
$blueCol: #4267b2;
$main-font: "OpenSans";
$title-font: "FiraSans";
$cursive-font: "Pacifico";

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: $main-font !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

#root {
    width: 100%;
    height: 100%;
}

#root>div {
    width: 100%;
    height: 100%;
}

#page {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font !important;
    font-weight: 600 !important;
}

p {
    font-family: $main-font !important;
}

a {
    color: $greenCol;
}

a:hover {
    color: $greenCol;
    text-decoration: none;
}

.text-danger {
    color: $pinkCol !important;
}

/* Alerts */

.alert-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-wrap.show {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 9999999;
}

.alert-wrap.show~.wrapper {
    height: 100%;
    overflow: hidden;
}

.alert-wrap.hide {
    width: 0;
    height: 0;
    background-color: transparent;
    z-index: 0;
    overflow: hidden;
}

.alert-card {
    width: 270px;
    padding: 1rem 0 !important;
    box-shadow: 0 3px 10px rgba(103, 97, 90, 0.25) !important;
}

.alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 270px;
}

.alert-info-title {
    font-family: $title-font;
    font-size: 16px;
    text-align: center;
}

.alert-info-text {
    margin: 8px 1rem 0 1rem;
    color: $blackCol;
    font-family: $main-font;
    font-size: 13px;
    text-align: center;
}

.alert-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.alert-buttons .btn {
    flex: 1;
    font-family: $title-font;
    font-size: 13px;
}

.alert-buttons .btn:active,
.alert-buttons .btn:focus {
    box-shadow: none;
    outline: none;
}

/*====================== 
  Form UI Elements 
  ======================*/

/* Buttons */

button:focus {
    outline: none;
    font-family: $title-font !important;
}

.btn-white {
    padding: 12px 32px;
    background-color: $whiteCol;
    border: 2px solid $whiteCol;
    border-radius: 25px;
    color: $blackCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-white:hover {
    background-color: $greenCol;
    color: $whiteCol;
}

.btn-white.focus,
.btn-white:focus {
    box-shadow: none;
}

.btn.btn-sm {
    padding: 6px 32px;
}

.btn-link {
    padding: 6px 0;
    color: $greenCol;
    font-size: 13px;
}

.btn-link:hover {
    color: $greenCol;
}

.btn-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.btn-wrapper img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

/* green */

.green-button {
    display: inline-block;
    background-color: $greenCol;
    border: 2px solid $greenCol;
    border-radius: 25px;
    text-align: center;
}

.btn-green {
    position: relative;
    z-index: 2;
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-green:hover {
    background-color: transparent;
    color: $whiteCol;
}

.btn-green.focus,
.btn-green:focus {
    box-shadow: none;
}

/* pink */

.pink-button {
    display: inline-block;
    background-color: $pinkCol;
    border: 2px solid $pinkCol;
    border-radius: 25px;
}

.btn-pink {
    position: relative;
    z-index: 2;
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-pink:hover {
    background-color: transparent;
    color: $whiteCol;
}

.btn-pink.focus,
.btn-pink:focus {
    box-shadow: none;
}

/* blue */

.blue-button {
    display: inline-block;
    background-color: $blueCol;
    border: 0 solid $blueCol;
    border-radius: 25px;
    text-align: center;
}

.btn-blue {
    position: relative;
    z-index: 2;
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-blue:hover {
    background-color: transparent;
    color: $whiteCol;
}

.btn-blue.focus,
.btn-blue:focus {
    box-shadow: none;
}

/* pink outline button */

.pink-button-outline {
    display: inline-block;
    background-color: $whiteCol;
    border: 2px solid $pinkCol;
    border-radius: 25px;
}

.btn-pink-outline {
    position: relative;
    z-index: 2;
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $pinkCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-pink-outline:hover {
    background-color: transparent;
    color: $whiteCol;
}

.btn-pink-outline.focus,
.btn-pink-outline:focus {
    box-shadow: none;
}

/* green outline button */

.green-button-outline {
    background-color: $whiteCol;
    border: 2px solid $greenCol;
    border-radius: 25px;
}

.btn-green-outline {
    position: relative;
    z-index: 2;
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $greenCol;
    font-family: $title-font;
    font-size: 14px;
}

.btn-green-outline:hover {
    background-color: transparent;
    color: $whiteCol;
}

.btn-green-outline.focus,
.btn-green-outline:focus {
    box-shadow: none;
}

/* back */

.btn-block {
    width: 100%;
}

.btn-block .btn-blue {
    padding: 11px 10px 9px 10px;
}

.btn-back {
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
}

.btn-shadow {
    width: 0;
    height: 0;
    border-radius: 25px;
    opacity: 0;
    -webkit-transition: all 0.35s ease-out 0.15s;
    -moz-transition: all 0.35s ease-out 0.15s;
    -o-transition: all 0.35s ease-out 0.15s;
    transition: all 0.35s ease-out 0.15s;
}

.btn-shadow.black-shadow {
    background-color: $blackCol;
}

.btn-shadow.white-shadow {
    background-color: $whiteCol;
}

.btn-wrapper:hover .btn-shadow {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.btn-wrapper:hover .btn.white-text {
    color: $blackCol;
}

.btn-image {
    padding: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.btn-image:hover {
    box-shadow: none;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.btn-image:focus,
.btn-image.focus {
    box-shadow: none;
}

.btn-image .link-image {
    width: 140px;
}

/* Form Elements */

.form {
    padding: 0 10px;
}

.form-title {
    margin-top: 16px;
    font-size: 16px;
}

.form-group {
    margin-bottom: 8px;
}

.form-group input {
    border-radius: 8px !important;
}

.form-group label {
    margin-bottom: 2px;
}

.form-control {
    border-color: $greenCol;
    font-size: 13px;
}

.form-control:focus {
    border-color: $greenCol;
    box-shadow: none;
}

/* select input styles */

.select-wrap {
    width: 100%;
}

.select-wrap .input-group {
    align-items: center;
}

.select-wrap .input-group .btn-arrow {
    position: relative;
    width: 26px;
    height: 26px;
    padding: 0;
    margin: 3px;
}

.select-wrap .input-group .btn-arrow:focus {
    box-shadow: none;
}

.select-wrap .input-group .btn-arrow img {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.select-wrap .input-group .btn-arrow img.img-active {
    opacity: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.select-wrap .input-group .btn-arrow:active img {
    opacity: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.select-wrap .input-group .btn-arrow:not(.btn-arrow-dis):active img.img-active {
    opacity: 1;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.select-wrap .input-group .btn-arrow .img-dis {
    opacity: 0;
}

.select-wrap .input-group .btn-arrow.btn-arrow-dis img {
    opacity: 0;
}

.select-wrap .input-group .btn-arrow.btn-arrow-dis .img-dis {
    opacity: 1;
}

.form-control.select-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 36px;
    padding: 4px 8px;
    border-color: $greenCol;
    border-width: 2px;
    border-radius: 25px !important;
    color: $blackCol;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.form-control.select-input:focus {
    color: $blackCol;
    border-color: $greenCol;
    box-shadow: none;
}

.form-control.select-input p {
    margin: 0;
}

@media all and (max-width: 480px) {
    .form-control.select-input p {
        font-size: 12px;
    }
}

.form-control.select-input input {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    border-width: 0;
    background-color: transparent;
    color: $blackCol;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.form-control.select-input input:focus {
    outline: 0;
}

.calc-dropdown-wrap {
    position: relative;
    z-index: 1;
}

.calc-dropdown-wrap.active {
    z-index: 9999;
}

.calc-dropdown {
    position: relative;
}

.calc-dropdown .form-control.select-input {
    z-index: 1;
}

.calc-dropdown .form-control.select-input:focus {
    font-family: $main-font !important;
}

.drop-list {
    display: none;
}

.drop-list.active {
    position: absolute;
    top: calc(100% + 0px);
    left: 16px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    border: 1px solid $darkGrayCol;
    background-color: $whiteCol;
}

.drop-list-item {
    width: 100%;
    padding: 6px 16px;
    border-width: 0;
    border-bottom: 1px solid $darkGrayCol;
    background-color: $whiteCol;
    font-family: $title-font;
}

.drop-list-item:last-child {
    border-bottom-width: 0;
}

/*====================
  Content Elements 
  ====================*/

/* Cards */

.card-wrap {
    position: relative;
}

.card {
    position: relative;
    z-index: 3;
    border-width: 0;
    border-radius: 25px;
    padding: 35px 25px;
}

.card-wrap::before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -6px;
    left: 12px;
    width: calc(100% - 24px);
    height: 32px;
    border-radius: 25px;
    background-color: $whiteCol;
    opacity: 0.2;
}

.card-wrap::after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -12px;
    left: 24px;
    width: calc(100% - 48px);
    height: 32px;
    border-radius: 25px;
    background-color: $whiteCol;
    opacity: 0.2;
}

/* Page Content */

#page {
    width: 100%;
}

.wrapper {
    width: 100%;
    min-height: 100%;
    padding-top: 130px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
}

/*.scroll.wrapper {
    padding-top: 58px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}*/

.h-100 {
    height: 100%;
}

/* Loader */
.loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.loader.show {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 99999;
}

.loader.show~.wrapper {
    height: 100%;
    overflow: hidden;
}

.loader.hide {
    width: 0;
    height: 0;
    background-color: transparent;
    z-index: 0;
    overflow: hidden;
}

.loader-overflow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 80px);
    padding-bottom: 200px;
    background-color: rgba(255, 255, 255, 0.85);
}

.loader-frame {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 15px;
}

.loader-week-frame {
    height: 100%;
}

.week-loader {
    width: 100%;
    height: 100%;
}

.week-loader-day {
    width: 100%;
    height: 42px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.loader-menu-frame {
    display: flex;
    height: 100%;
}

.menu-loader {
    display: flex;
    width: 100%;
    height: 100%;
}

.menu-loader-slide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 200px);
    overflow: hidden;
}

.menu-loader-img {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 360px;
    border-radius: 16px;
    background-color: $darkGrayCol;
}

.menu-loader-text {
    margin-top: 16px;
}

.menu-loader-title {
    width: 84%;
    height: 24px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-rate {
    display: flex;
    justify-content: space-between;
    width: 46%;
    height: 23px;
    margin-bottom: 12px;
}

.menu-loader-start {
    width: 50%;
    height: 23px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-num {
    width: 44%;
    height: 23px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-ingredients {
    width: 94%;
    height: 48px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-info1 {
    width: 24%;
    height: 24px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-info2 {
    width: 34%;
    height: 24px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.menu-loader-control {
    position: relative;
    width: 100px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.menu-loader-control-day {
    width: calc(100% - 20px);
    height: 66px;
    margin-bottom: 8px;
    margin-left: 20px;
    border-radius: 8px;
    background-color: $darkGrayCol;
}

.loader-gif {
    width: 65px;
    height: 65px;
}

/* Content Mobile */

@media all and (max-width: 991px) {
    .wrapper {
        padding-top: 58px;
    }
}

/*========================== 
    Header Component Styles 
  ==========================*/

header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: $whiteCol;
}

.header-wrap {
    width: 100%;
}

.header-full {
    display: block;
    width: 100%;
}

.header-mobile {
    display: none;
}

/* header info */

.top-info-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.scroll header .top-info-wrap {
    height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.top-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.top-info-contacts {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.top-info-contacts .lang-list {
    position: absolute;
    left: 0;
    width: 200px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $whiteCol;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}

.top-info-contacts .lang-list .lang-item {
    display: inline-block;
    width: 100%;
    padding: 6px;
    border-width: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $whiteCol;
    text-align: center;
    cursor: pointer;
}

.top-info-contacts .lang-list .lang-item:hover {
    background-color: $grayCol;
}

.top-info-contacts .lang-list.hide {
    top: -500px;
    z-index: 0;
    height: 0;
    opacity: 0;
}

.top-info-contacts .lang-list.show {
    top: 39px;
    z-index: 2;
    height: auto;
    opacity: 1;
}

.contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding-left: 0;
    padding-right: 25px;
    border-width: 0;
    background-color: transparent;
    font-family: $main-font !important;
}

.contact-item:active,
.contact-item:focus {
    font-family: $main-font !important;
}

.contact-item .icon-left {
    width: 14px;
    height: 14px;
    margin-bottom: 1px;
    margin-right: 6px;
}

.contact-item .icon-right {
    width: 9px;
    height: 9px;
    margin-left: 6px;
    margin-top: 1px;
}

.contact-item span {
    color: $blueCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.contact-item:hover span {
    color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.contact-item a {
    color: $blackCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.contact-item a:hover {
    color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.top-info-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    padding-left: 20px;
    border-width: 0;
    background-color: transparent;
}

.profile-item span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    background-color: $greenCol;
    color: $whiteCol;
    font-family: $main-font;
    font-size: 12px;
}

.profile-item .basket-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.menu-wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.scroll header .menu-wrap {
    height: 58px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 50px;
}

.logo .logo-text img {
    height: 36px;
}

.menu-list {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 25px;
}

.menu .menu-item {
    display: flex;
    padding: 0 25px;
}

.menu .menu-item a {
    color: $darkCol;
    font-family: "FiraSans";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.menu .menu-item:hover a {
    color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.menu .menu-item.active a {
    color: $greenCol;
}

/* Header Mobile */

@media all and (max-width: 991px) {
    .header-full {
        display: none;
    }

    .header-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        padding: 0 20px;
        background-color: $blackCol;
    }

    .header-mobile-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    .header-mobile .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
        padding: 0;
        border-width: 0;
        background-color: transparent;
    }

    .header-mobile .menu-btn .basket-icon {
        width: 24px;
        height: 24px;
    }

    .header-mobile .logo {
        display: flex;
        align-items: center;
        margin-left: 20px;
    }

    .header-mobile .logo .logo-text img {
        height: 20px;
        margin-left: 8px;
        margin-bottom: 4px;
    }

    .header-mobile .logo .logo-text img.img {
        height: 38px;
        margin-right: 8px;
    }

    .header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .menu-mobile-wrap {
        position: absolute;
        top: 58px;
        right: 0;
        z-index: 1000;
        width: 0;
        height: 100%;
        background-color: $whiteCol;
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15);
        -webkit-transition: all 0.35s ease-out 0s;
        -moz-transition: all 0.35s ease-out 0s;
        -o-transition: all 0.35s ease-out 0s;
        transition: all 0.35s ease-out 0s;
    }

    .menu-mobile-wrap.active {
        width: 320px;
        -webkit-transition: all 0.35s ease-out 0s;
        -moz-transition: all 0.35s ease-out 0s;
        -o-transition: all 0.35s ease-out 0s;
        transition: all 0.35s ease-out 0s;
    }

    .menu-mobile-wrap .profile-menu {
        position: absolute;
        left: 0;
        display: block;
        width: 320px;
        padding: 0;
    }

    .menu-mobile-wrap .profile-menu .menu-item {
        width: 100%;
        border-bottom: 1px solid $darkGrayCol;
    }

    .menu-mobile-wrap .profile-menu .menu-item:last-child {
        border-width: 0;
    }

    .home-menu-wrap {
        width: 100%;
        border-bottom: 1px solid $darkGrayCol;
    }
}

@media all and (max-width: 480px) {
    .header-mobile {
        padding: 0;
    }
}

/*========================= 
  Top Slider Styles 
  =========================*/

.top-info {
    width: 100%;
}

.slider {
    position: relative;
    width: 100%;
    height: 800px;
}

.slider .slide-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    padding-top: 130px;
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0;
}

.slider .slide.active {
    z-index: 1;
    opacity: 1;
}

.slider .slide-content {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 670px;
    padding-bottom: 40px;
}

.slide-content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 40px;
}

.slide-content-text h2 {
    margin: 24px 0;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 72px;
    letter-spacing: 1px;
    text-align: center;
}

.slide-content-text p {
    margin-bottom: 32px;
    color: $whiteCol;
    font-family: $main-font;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.slide-content-text .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* shadow */

.slider .slide-shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: $blackCol;
    opacity: 0.5;
}

/* controls */

.slide-control {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 100%;
    padding-top: 125px;
}

.slide-control .btn-slide-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
}

.slide-control .slide-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 16px 0;
    border-width: 0;
    border-radius: 24px;
    background-color: $whiteCol;
}

.slide-control .slide-btn-img {
    width: 24px;
    height: 24px;
}

.slide-control .btn-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    padding: 0;
    border: 3px solid $whiteCol;
    border-radius: 50px;
    background-position: 50% 50%;
    background-size: auto 90px;
    background-color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.slide-control .btn-slide img {
    width: 60px;
    height: 60px;
}

.slide-control .btn-slide.active {
    background-color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

/* Top Slider Mobile */

@media all and (max-width: 991px) {
    .slider {
        height: 422px;
    }

    .slide-control {
        width: 68px;
        padding-top: 0;
    }

    .slide-control .btn-slide-wrap {
        width: 60px;
        height: 60px;
    }

    .slide-control .btn-slide {
        width: 58px;
        height: 58px;
    }

    .slide-control .btn-slide img {
        width: 38px;
        height: 38px;
    }

    .slider .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
    }

    .slider .slide-content {
        height: 100%;
        padding-bottom: 0;
    }

    .slide-content-text {
        padding: 0 60px;
    }

    .slide-content-text h2 {
        font-size: 24px;
    }

    .slide-content-text p {
        font-size: 13px;
    }

    .slide-content-text .btn-wrap {
        flex-direction: column;
        margin: 24px 0;
    }

    .slide-content-text .btn-wrap .btn-wrapper {
        margin-right: 0 !important;
        margin-bottom: 8px;
    }
}

@media all and (max-width: 480px) {
    .slider {
        height: 540px;
    }

    .slide-content-text {
        padding-left: 0;
    }
}

/*======================
  Sections Style 
  ======================*/

section {
    width: 100%;
    padding: 50px 0;
}

.title-wrap {
    width: 100%;
}

section .section-title {
    margin-bottom: 32px;
    color: $blackCol;
    font-family: $title-font;
    font-size: 45px;
    line-height: 45px;
}

section .section-sub {
    margin-bottom: 2rem;
}

section .section-pattern {
    width: 100%;
}

section .section-title::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    margin-top: 20px;
    background-color: $pinkCol;
}

section .section-title span {
    color: $greenCol;
}

section .subtitle {
    color: $greenCol;
    font-family: $cursive-font !important;
    font-size: 20px;
    font-weight: normal !important;
    text-align: center;
}

section .btn-wrap {
    margin: 32px 0;
}

/* small-section */

section.small-section .section-title {
    margin: 0;
    font-size: 36px;
}

section.small-section .section-title::after {
    margin-top: 10px;
}

/* colored */

section.colored {
    background-color: $greenCol;
}

section.colored .subtitle {
    color: $blackCol;
}

section.colored .section-title {
    color: $whiteCol;
}

section.colored .section-title span {
    color: $blackCol;
}

section.colored .section-title::after {
    background-color: $whiteCol;
}

/* image-bg */

section.image-bg {
    padding: 0;
}

section.image-bg .section-title {
    color: $whiteCol;
}

.image-shadow {
    width: 100%;
    height: 100%;
    padding: 50px 0;
    background-color: rgba(0, 0, 0, 0.55);
}

/* centred */

section.centred {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

section.centred .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

section.centred .section-sub {
    text-align: center;
}

@media all and (max-width: 991px) {
    section {
        padding: 24px 0;
    }

    section .section-title {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 24px;
    }

    section .section-title::after {
        height: 2px;
        margin-top: 16px;
    }

    .image-shadow {
        padding: 24px 0;
    }

    .inst-title {
        padding: 24px 0 16px 0 !important;
    }

    section .section-sub {
        margin-bottom: 1rem;
    }
}

@media all and (max-width: 480px) {
    section .section-title {
        font-size: 20px !important;
    }
}

/*=================== 
  About Section 
  ===================*/

.about-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    border-radius: 25px;
}

.about-info-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.about-info-back-bg {
    width: 0;
    height: 0;
    border-radius: 25px;
    background-color: $greenCol;
}

.about-info-card:hover .about-info-back-bg {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.about-info-card:not(:hover) .about-info-back-bg {
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.about-info-card .about-info-wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.about-info-card .about-info-img {
    position: relative;
    width: 60px;
    height: 60px;
}

.about-info-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
}

.about-info-card img.icon {
    opacity: 1;
}

.about-info-card img.icon-white {
    opacity: 0;
}

.about-info-card:hover img.icon {
    opacity: 0;
}

.about-info-card:hover img.icon-white {
    opacity: 1;
    -webkit-transition: all 0.25s ease-out 0.1s;
    -moz-transition: all 0.25s ease-out 0.1s;
    -o-transition: all 0.25s ease-out 0.1s;
    transition: all 0.25s ease-out 0.1s;
}

.about-info-card h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    color: $blackCol;
    font-size: 16px;
    text-align: center;
}

.about-info-card:hover h4 {
    color: $whiteCol;
    -webkit-transition: all 0.25s ease-out 0.1s;
    -moz-transition: all 0.25s ease-out 0.1s;
    -o-transition: all 0.25s ease-out 0.1s;
    transition: all 0.25s ease-out 0.1s;
}

.about-info-card h4::after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    margin: 8px 0 0 0;
    background-color: $greenCol;
}

.about-info-card:hover h4::after {
    background-color: $whiteCol;
    -webkit-transition: all 0.25s ease-out 0.1s;
    -moz-transition: all 0.25s ease-out 0.1s;
    -o-transition: all 0.25s ease-out 0.1s;
    transition: all 0.25s ease-out 0.1s;
}

.about-info-card p {
    margin-bottom: 0;
    color: $blackCol;
    font-size: 13px;
    text-align: center;
}

.about-info-card:hover p {
    color: $whiteCol;
    -webkit-transition: all 0.25s ease-out 0.1s;
    -moz-transition: all 0.25s ease-out 0.1s;
    -o-transition: all 0.25s ease-out 0.1s;
    transition: all 0.25s ease-out 0.1s;
}

@media all and (max-width: 991px) {
    .about-info-card {
        margin-bottom: 8px;
        padding: 8px 0;
    }

    .about-info-card p {
        margin-bottom: 8px;
    }
}

/*==================== 
  Calculate Plan 
  ====================*/

.calculate {
    background-image: url(../assets/imgs/section/pitanie.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.calc {
    display: flex;
    flex-direction: row;
    min-height: 268px;
    margin-bottom: 8px;
}

.calc .calc-steps {
    flex-grow: 0;
    flex-shrink: 0;
    width: 60px;
}

.calc .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: 26px;
    border-radius: 20px;
    background-color: $greenCol;
}

.calc .step.focus,
.calc .step:focus {
    box-shadow: none;
}

.calc .step.active {
    background-color: $whiteCol;
    border: 2px solid $greenCol;
}

.calc .step .step-icon {
    width: 24px;
    height: 24px;
}

.calc .step:not(.last)::after {
    content: "";
    position: absolute;
    top: 40px;
    display: block;
    width: 2px;
    height: 16px;
    margin: 6px 0;
    background-color: $greenCol;
}

.calc .calc-info {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: -15px;
}

.calc .calc-info .calc-info-content {
    position: relative;
    width: 100%;
}

.calc .input-group {
    display: flex;
    flex-direction: row;
}

.calc h4 {
    margin-top: 16px;
    font-size: 14px;
}

.calc-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0 0 0;
    padding-left: 0;
}

.calc-result {
    display: flex;
    align-items: stretch;
    text-align: center;
}

.calc-result strong {
    color: $pinkCol;
}

.calc-result-info {
    width: 100%;
    padding: 0 10px;
}

.calc-result-info .imgs-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    padding: 0 8px;
}

.calc-result-info .imgs-info-big {
    flex-grow: 0;
    flex-shrink: 0;
    width: 66.4%;
    margin-right: 8px;
    border-radius: 25px;
    overflow: hidden;
}

.imgs-info-small-wrap {
    flex-grow: 1;
    flex-shrink: 1;
}

.calc-result-info .imgs-info-small {
    width: 100%;
    overflow: hidden;
}

.imgs-info-inner {
    width: 100%;
}

.imgs-info-small .imgs-info-inner {
    margin-bottom: 8px;
    border-radius: 25px;
}

.calc-result-info .img-link-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.45);
}

@media all and (max-width: 768px) {
    .calc-btn-wrap {
        margin-top: 24px;
        padding-left: 0;
    }
}

/*===================
  Food Menu 
  ===================*/

/* food types */

.food-card-tabs-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
}

.food-card-tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 0;
    background-color: $whiteCol;
}

.food-card-tabs .tab-card-wrap {
    display: flex;
    width: 176px;
    padding: 8px;
}

.food-card-tabs .tab-card {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 160px;
    height: 90px;
    padding: 0;
    border-width: 0;
    border-radius: 16px;
    background-size: 160px;
    overflow: hidden;
    background-color: $grayCol;
}

.food-card-tabs .active-card-back {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 162px;
    height: 92px;
    margin: 7px;
    border-radius: 16px;
    background-color: $greenCol;
    box-shadow: 0 0 0 0.2rem $greenCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.food-card-tabs .tab-card-img {
    flex-grow: 0;
    flex-shrink: 0;
    width: 80px;
    height: 90px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.food-card-tabs .tab-card-text {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 8px;
}

.food-card-tabs .tab-card h4 {
    margin-bottom: 4px;
    color: $blackCol;
    font-size: 14px;
    text-align: left;
}

.food-card-tabs .tab-card h4::after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    margin-top: 2px;
    background-color: $pinkCol;
}

.food-card-tabs .tab-card p {
    margin: 0;
    color: $darkCol;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
}

/* menu-types */

.program-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 148px;
    overflow-x: hidden;
}

.program-tabs-slide {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 20px;
    border-width: 0;
    background-color: $whiteCol;
}

.program-tabs-slide img {
    width: 24px;
    height: 24px;
}

.program-tabs-slides-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 148px;
    overflow: hidden;
}

.program-tabs-slides-scroll {
    position: absolute;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.program-tabs-slides-scroll .card-fade-wrap {
    position: relative;
    z-index: 2;
}

.program-tabs .program-type-cards {
    display: flex;
    flex-shrink: 0;
    margin: 0 8px;
    border-radius: 20px;
    border-left: 2px solid $darkGrayCol;
    border-right: 2px solid $darkGrayCol;
    background-color: $whiteCol;
}

.program-tabs .program-type-cards.active {
    border-left: 2px solid $greenCol;
    border-right: 2px solid $greenCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.program-tabs .program-card {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
    padding: 0 8px;
    margin-right: 8px;
    border-radius: 18px;
    border-width: 0;
    background-color: $whiteCol;
}

.program-tabs .program-type-cards.active .program-card {
    padding: 12px 16px;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.program-tabs .program-type-cards.active .program-card.active {
    background-color: $greenCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.program-tabs .program-card:last-child {
    margin-right: 0;
}

.program-tabs .program-card .product-card-img {
    position: relative;
    width: 40px;
    height: 40px;
}

.program-tabs .program-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
}

.program-tabs .program-card img:first-child {
    opacity: 1;
}

.program-tabs .program-card img:last-child {
    opacity: 0;
}

.program-tabs .program-type-cards.active .program-card.active img:first-child {
    opacity: 0;
}

.program-tabs .program-type-cards.active .program-card.active img:last-child {
    opacity: 1;
}

.program-tabs .program-card h4 {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 14px;
}

.program-tabs .program-type-cards.active .program-card.active h4 {
    color: $whiteCol;
}

.program-tabs .program-card p {
    font-size: 12px;
    line-height: 13px;
    padding: 0 2px;
    color: #696b7c;
    font-weight: 500;
}

.program-tabs .program-type-cards.active .program-card.active p {
    color: $whiteCol;
}

/* menu-info-slider */

.food-menu-card {
    position: relative;
    padding: 0;
}

.food-menu-left {
    position: absolute;
    top: calc(50% - 250px);
    right: 75%;
}

.food-menu-right {
    position: absolute;
    top: calc(50% - 250px);
    left: 75%;
}

.food-card {
    display: flex;
    flex-direction: row;
}

.food-card-info {
    width: 100%;
}

.food-card-demo {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 16px;
}

.slider-demo-menu {
    width: 100%;
    height: 100%;
}

.food-slider {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
}

/* week day select */

.week-select {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    width: 60px;
    margin-right: 20px;
}

.week-select-btn {
    position: relative;
    width: 100%;
    height: 42px;
    padding: 0 8px;
    margin-bottom: 8px;
    border-width: 0;
    border-radius: 8px;
    color: $blackCol;
    font-family: $title-font;
    font-weight: 700;
    line-height: 13px;
    background-color: $grayCol;
}

.week-select-btn.active {
    color: $whiteCol;
    background-color: $greenCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.week-select-btn.active::after {
    content: "";
    position: absolute;
    right: -8px;
    top: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid $greenCol;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
}

.week-select-btn span {
    font-size: 10px;
}

.week-change {
    position: relative;
    width: 100%;
    height: 42px;
    padding: 0 8px;
    margin-bottom: 8px;
    border-width: 0;
    border-radius: 8px;
    background-color: $greenCol;
}

.week-change img {
    width: 20px;
    height: 20px;
}

/* menu items slider */

.menu-items-wrap {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
}

.slide-menu-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 200px);
    overflow: hidden;
}

.slide-menu-text {
    margin-top: 16px;
}

.slide-menu-text h3 {
    font-size: 20px;
}

.slide-menu-text h4 {
    font-size: 13px;
    font-family: $main-font;
    font-weight: 500;
}

.slide-menu-text p {
    margin: 0;
}

.slide-menu-item-bg {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    border-radius: 16px;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
}

.slide-menu-item-bg img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}

.slide-info-comment {
    margin-top: 12px;
    margin-bottom: 12px;
    color: $darkCol;
}

.slide-info-comment a {
    color: $pinkCol;
    font-weight: 600;
}

.slide-info-calc {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 106px;
    height: 135px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.slide-info-calc .donut-hole {
    fill: $grayCol;
}

.slide-info-calc .donut-ring {
    fill: transparent;
    stroke: $darkGrayCol;
}

.slide-info-calc .donut-segment {
    fill: transparent;
}

.slide-info-calc .donut-segment.car-segment {
    stroke: $greenCol;
}

.slide-info-calc .donut-segment.fat-segment {
    stroke: $pinkCol;
}

.slide-info-calc .donut-segment.pro-segment {
    stroke: $blueCol;
}

.slide-info-calc .donut-info {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 110px;
}

.slide-info-calc .donut-info h4 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
}

.slide-info-calc .donut-info p {
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    color: $darkCol;
}

.slide-info-lip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #fff;
}

.slide-info-lip p {
    margin: 0;
    font-size: 11px;
}

.slide-info-lip p:first-child {
    color: $blueCol;
    margin-right: 4px;
}

.slide-info-lip p:nth-child(2) {
    color: $pinkCol;
    margin-right: 4px;
}

.slide-info-lip p:last-child {
    color: $greenCol;
}

/* demo menu */

.slider-demo-menu .control {
    position: relative;
    width: 100px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.slider-demo-menu .control .control-slide {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 80px;
    height: 64px;
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.slider-demo-menu .control .control-slide:hover {
    opacity: 0.75;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.slider-demo-menu .control .control-slide.active {
    opacity: 0.45;
}

.slider-demo-menu .control .control-slide p {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 3px 0;
    color: $whiteCol;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    background-color: $greenCol;
}

.slide-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
}

.slide-info h3 {
    font-size: 24px;
    line-height: 20px;
}

.slide-info p strong {
    color: $pinkCol;
}

.slide-info h4 {
    font-size: 14px;
}

.total-price {
    margin: 24px 0;
}

.total-price h3 {
    font-size: 16px;
}

.total-price h3 strong {
    color: $pinkCol;
    font-size: 26px;
    font-weight: 700;
}

.total-price h3 span {
    color: $darkCol;
    text-decoration: line-through;
}

.total-price p {
    margin-bottom: 0;
    color: $greenCol;
    font-family: $title-font !important;
    font-size: 18px;
}

.total-price-btn-wrap {
    margin: 16px 0;
}

.total-price>a {
    color: $pinkCol;
    text-decoration: underline;
}

.total-price>a:hover {
    color: $blackCol;
    text-decoration: underline;
}

.total-price>a strong {
    font-family: $title-font;
}

/* rating start */

.rating-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rating .rating-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0 4px 0 0;
    border-width: 0;
    background-color: $whiteCol;
}

.rating .rating-btn img {
    width: 19px;
    height: 19px;
}

.rate-num {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding-top: 2px;
}

.rating-info .rate-num p {
    margin: 0;
    color: $blackCol;
    font-size: 12px;
    font-weight: 600;
    line-height: 21px;
}

.top-menu-info {
    display: none;
}

.bottom-menu-info {
    display: block;
}

.bottom-menu-info h3 small {
    color: $darkCol;
    font-size: 14px;
}

@media all and (max-width: 991px) {
    .food-slider {
        flex-direction: column;
    }

    .week-select {
        flex-direction: row;
        width: auto;
        margin-right: 0;
    }

    .week-select-btn {
        width: 10%;
        margin-right: 1%;
        margin-bottom: 16px;
    }

    .week-select-btn.active::after {
        right: calc(50% - 6px);
        top: calc(100% + 2px);
        border-top: 6px solid $greenCol;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
    }

    .top-menu-info {
        display: block;
    }

    .bottom-menu-info {
        display: none;
    }

    .week-change {
        width: 10%;
        margin-right: 1%;
        margin-bottom: 16px;
    }
}

@media all and (max-width: 540px) {
    .week-select-btn span {
        font-size: 8px;
    }

    .week-select-btn {
        padding: 0;
    }

    .week-change {
        padding: 0;
    }
}

/*===================
  Sales Section
  ===================*/

.sales-plans {
    background-image: url(../assets/imgs/section/pexels-photo-1640774.jpg);
    background-size: cover;
    background-position: 50% 100%;
}

.sales-plans-wrap {
    display: flex;
    padding: 2rem;
    background-color: $whiteCol;
    border-radius: 20px;
}

.price-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
}

.price-info-wrap h3 {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
}

.price-info-wrap h3 span {
    color: $greenCol;
}

.price-info-wrap p {
    margin-bottom: 0;
    text-align: center;
}

.price-info-wrap p strong {
    color: $greenCol;
}

.price-info-wrap h4 {
    margin-bottom: 16px;
    text-align: center;
    line-height: 24px;
}

.price-info-wrap h4 small {
    font-size: 14px;
}

.price-info-wrap .old-price {
    color: $darkCol;
    font-size: 20px;
    text-decoration: line-through;
}

.price-info-wrap .new-price {
    color: $pinkCol;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.price-info-wrap h4 span {
    color: $pinkCol;
}

.price-list-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    width: 234px;
}

.price-list-wrap h3 {
    font-size: 16px;
}

.price-list-wrap .price-btns {
    flex-grow: 0;
    flex-shrink: 0;
}

.price-list-wrap .price-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 64px;
    margin-bottom: 8px;
    padding: 0 16px;
    border-width: 0;
    border-radius: 16px;
    background-color: $grayCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.price-list-wrap .price-card.active::after {
    content: "";
    position: absolute;
    left: calc(100% + 2px);
    top: 26px;
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid $greenCol;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
}

.price-list-wrap .price-card.active.orange::after {
    content: "";
    position: absolute;
    left: calc(100% + 2px);
    top: 22px;
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid $pinkCol;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
}

.price-list-wrap .price-card .img-wrap {
    position: relative;
    width: 28px;
    height: 28px;
    margin-right: 16px;
}

.price-list-wrap .price-card.active {
    background-color: $greenCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.price-list-wrap .price-card.orange.active {
    background-color: $pinkCol;
    -webkit-transition: all 0.45s ease-out 0s;
    -moz-transition: all 0.45s ease-out 0s;
    -o-transition: all 0.45s ease-out 0s;
    transition: all 0.45s ease-out 0s;
}

.price-list-wrap .price-card img {
    width: 28px;
    height: 28px;
}

.price-list-wrap .price-card .price-card-info {
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 18px;
    text-align: left;
}

.price-list-wrap .price-card h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}

.price-list-wrap .price-card.active h4 {
    color: $whiteCol;
}

.price-list-wrap .price-card p {
    margin: 0;
    font-size: 13px;
}

.price-list-wrap .price-card.active p {
    color: $whiteCol;
}

.price-info-wrap {
    flex-grow: 1;
    flex-shrink: 1;
}

@media all and (max-width: 991px) {}

/*================ 
  App Links 
  ================*/

.app-links .subtitle {
    text-align: left;
}

.device {
    width: 100%;
}

.device img {
    width: 100%;
}

.device-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-left: 40px;
}

.app-link-wrap {
    display: flex;
    flex-wrap: wrap;
}

.delivery-map {
    flex-direction: column !important;
    padding: 0 !important;
}

@media all and (max-width: 991px) {
    .device img {
        display: block;
        width: 240px;
        margin: 0 auto;
        margin-bottom: 16px;
    }

    .device-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        padding: 0 16px;
    }

    .device-info p {
        text-align: center;
    }

    .app-links .subtitle {
        text-align: center;
    }

    .app-links .section-title {
        text-align: center;
    }

    section.app-links .section-title::after {
        margin: 16px auto 0 auto;
    }
}

/*===================
  Reviews
  ===================*/

.reviews {
    background-color: $whiteCol;
}

.reviews-wrap {
    width: 100%;
}

.reviews-wrap img {
    width: 100%;
}

/*===================
  Faqs
  ===================*/

.faqs {
    flex-direction: column !important;
    background-color: $grayCol;
}

.faq-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.faq-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-tabs .faq-tab {
    position: relative;
    display: flex;
    padding: 4px;
    border-width: 0;
    background-color: transparent;
}

.faq-tabs .faq-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 16px;
    background-color: $whiteCol;
}

.faq-tabs .faq-card img {
    width: 36px;
    height: 36px;
}

.faq-tabs .faq-card h3 {
    margin: 8px 0 0;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
}

.faq-tabs .faq-tab.active .faq-card {
    position: relative;
    background-color: $greenCol;
}

.faq-tabs .faq-tab.active .faq-card::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(100% + 4px);
    left: calc(50% - 6px);
    width: 0;
    height: 0;
    border-top: 6px solid $greenCol;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.faq-tabs .faq-tab.active .faq-card h3 {
    color: $whiteCol;
}

.faq-info {
    margin-top: 24px;
}

.faq-info .faq-info-list {
    background-color: $whiteCol;
}

.faq-info .info-item {
    position: relative;
    z-index: 0;
    width: 100%;
    border-width: 0;
    border-bottom: 1px solid $darkGrayCol;
    background-color: transparent;
}

.faq-info .info-item.active {
    z-index: 1;
}

.faq-info .info-item:last-child {
    border-width: 0;
}

.faq-info .info-item .info-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-width: 0;
    background-color: transparent;
}

.faq-info .info-item .info-item-title h3 {
    margin: 0;
    color: $darkCol;
    font-size: 14px;
    text-align: left;
}

.faq-info .info-item .info-item-title img {
    width: 9px;
    height: 9px;
    margin-left: 12px;
}

.faq-info .info-item .info-item-content {
    position: relative;
    height: 0;
    opacity: 0;
    padding: 0;
    z-index: 0;
}

.faq-info .info-item.active .info-item-content {
    height: auto;
    opacity: 1;
    padding: 0 20px 8px 20px;
    z-index: 1;
}

.faq-info .info-item.active .info-item-title h3 {
    color: $greenCol;
}

.faq-info .info-item .info-item-content p {
    height: 0;
    margin: 0;
    font-size: 12px;
    text-align: left;
}

.faq-info .info-item.active .info-item-content p {
    height: auto;
    margin-bottom: 1rem;
}

@media all and (max-width: 576px) {
    .faq-tabs {
        width: 100%;
    }

    .faq-tabs .faq-tab {
        flex: 1;
    }

    .faq-tabs .faq-card {
        width: 100%;
        height: 84px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .faq-tabs .faq-card img {
        width: 24px;
        height: 24px;
    }
}

@media all and (max-width: 480px) {
    .faq-tabs .faq-card h3 {
        font-size: 10px;
    }

    .faq-tabs .faq-card {
        height: 70px;
    }
}

/*===================
  Instagram Photos
  ===================*/

.inst-title {
    padding: 50px 0 25px 0;
    flex-direction: column !important;
}

.inst-title h4 {
    width: 100%;
}

.inst-title h3 {
    width: 100%;
}

.inst {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.inst>div>div>div {
    padding: 0;
}

.inst-card {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.inst-card .inst-card-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.inst-card .inst-card-inner img {
    width: 100%;
}

.inst-card .inst-card-hover {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.inst-card .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    background-color: rgba(135, 191, 156, 0.85);
    opacity: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.inst-card:hover .overlay {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.inst-card .overlay img {
    width: 28px;
    height: 28px;
}

@media all and (max-width: 991px) {
    .inst .small-dis {
        display: none;
    }
}

/*==========================
  Login & Registration Page
  ==========================*/

.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-title {
    background-image: url(../assets/imgs/section/photo-1494597564530-871f2b93ac55.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 40%;
}

.login-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    background-color: $grayCol;
}

.login-card .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 12px 0 4px 0;
}

.login-card .btn-wrap .btn-wrapper {
    flex: 1;
}

.login-card h4 {
    color: $darkCol;
    font-size: 13px;
}

.social-auth {
    width: 100%;
}

.social-wrap {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.social-text {
    position: relative;
    margin: 20px 0 24px 0;
    text-align: center;
    color: $darkCol;
    font-size: 12px;
}

.social-text::before {
    content: "";
    position: absolute;
    left: 24px;
    top: 10px;
    width: calc(50% - 60px);
    height: 1px;
    background-color: $darkGrayCol;
}

.social-text::after {
    content: "";
    position: absolute;
    right: 24px;
    top: 10px;
    width: calc(50% - 60px);
    height: 1px;
    background-color: $darkGrayCol;
}

@media all and (max-width: 440px) {
    .login-card .green-button.mr-2 {
        margin-right: 0 !important;
    }
}

.reg-types {
    width: 100%;
    margin-bottom: 12px;
}

.reg-types .btn-link {
    color: $darkCol;
}

.reg-types .btn-link.active {
    color: $greenCol;
    font-weight: 600;
    text-decoration: underline;
}

.radio-reg {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    border-width: 0;
    background-color: transparent;
}

.radio-reg.active {
    font-family: $title-font;
}

.radio-reg .radio-icon {
    position: relative;
    width: 12px;
    height: 12px;
    margin-right: 12px;
    border-radius: 6px;
    border: 2px solid $greenCol;
}

.radio-reg.active .radio-icon::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: $greenCol;
}

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    border-width: 0;
    background-color: transparent;
}

.switch .switch-check {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 56px;
    height: 30px;
    margin-right: 8px;
    border-radius: 15px;
    border: 2px solid $greenCol;
    background-color: $whiteCol;
}

.switch .switch-label {
    color: $greenCol;
    font-family: $title-font;
    text-align: left;
}

.switch.active .switch-check {
    background-color: $greenCol;
}

.switch .switch-check::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    background-color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.switch.active .switch-check::after {
    left: 28px;
    background-color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.order-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
}

.order-info .order-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.order-info .order-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 94px;
    margin-right: 16px;
    margin-left: 0;
    background-color: $whiteCol;
    border-radius: 24px;
}

.order-info .order-step:last-child {
    margin-right: 0;
    margin-left: 16px;
}

.order-info .order-step.active .step-num {
    color: $greenCol;
}

.order-info .order-step::after {
    content: "";
    position: absolute;
    left: calc(100% + 6px);
    width: 20px;
    height: 1px;
    background-color: $darkGrayCol;
}

.order-info .order-step:last-child::after {
    width: 0;
    height: 0;
}

.order-info .order-step img {
    width: 32px;
    height: 32px;
    margin-bottom: 2px;
}

.order-info .order-step .step-num {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    border-radius: 24px;
    color: $darkCol;
    font-size: 13px;
    text-align: center;
}

.order-info .order-step .step-num span {
    position: relative;
    font-family: $title-font;
    font-size: 13px;
    line-height: 14px;
}

.order-info .order-step .step-num span::before {
    content: "";
    position: absolute;
    top: -8px;
    left: calc(50% - 15px);
    width: 30px;
    height: 2px;
    background-color: $darkCol;
}

.order-info .order-step.active .step-num span::before {
    background-color: $greenCol;
}

/*=================
  Profile Page
  =================*/

.profile-title {
    background-image: url(../assets/imgs/section/pexels-photo-3673762.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.profile-wrap {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: flex-start;
    justify-content: center;
    background-color: $grayCol;
}

.profile-menu {
    padding: 24px 0;
}

.profile-menu .mobile-lang {
    padding: 24px 32px;
}

.profile-menu .mobile-lang .mobile-lang-item {
    padding: 4px 12px;
    margin-right: 8px;
    border: 1px solid $greenCol;
    border-radius: 8px;
    cursor: pointer;
}

.profile-menu .mobile-lang .mobile-lang-item.active {
    background-color: $greenCol;
    color: $whiteCol;
}

.profile-menu .mobile-lang .mobile-lang-item:hover {
    background-color: $greenCol;
    color: $whiteCol;
}

.profile-menu .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 2rem;
    border-width: 0;
    background-color: transparent;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.profile-menu .menu-item.active::after {
    content: "";
    position: absolute;
    left: calc(100% + 2px);
    top: calc(50% - 6px);
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid $greenCol;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.profile-menu .menu-item img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}

.profile-menu .menu-item h4 {
    margin: 0;
    color: $blackCol;
    font-size: 13px;
    text-align: left;
}

.profile-menu .menu-item.active {
    background-color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.profile-menu .menu-item.active h4 {
    color: $whiteCol;
}

.profile-info {
    width: 100%;
}

.profile-plans {
    width: 100%;
    border-top: 1px solid $darkGrayCol;
    background-color: $grayCol;
}

/* palns empty */

.empty {
    display: flex;
    align-items: center;
}

.empty .empty-img {
    width: 92px;
    height: 92px;
    margin-right: 24px;
}

.empty .empty-info {
    display: flex;
    flex-direction: column;
}

.empty .empty-info h4 {
    font-size: 16px;
}

.doc-item {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.doc-item .doc-item-icon {
    width: 28px;
    height: 28px;
    margin-right: 16px;
}

.doc-item p {
    margin: 0;
}

/* plans no empty */

.plans-wrap {
    width: 100%;
}

.plans-tab-bar {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.plan-tab {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 16px;
    border-width: 0;
    border-left: 2px solid $darkGrayCol;
    border-right: 2px solid $darkGrayCol;
    border-radius: 18px;
    color: $blackCol;
    font-size: 14px;
    line-height: 16px;
    background-color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-tab .plan-tab-img {
    position: relative;
    width: 48px;
    height: 48px;
}

.plan-tab .plan-tab-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    opacity: 0;
}

.plan-tab .plan-tab-img img:first-child {
    opacity: 1;
}

.plan-tab .plan-tab-img img:last-child {
    opacity: 0;
}

.plan-tab.active .plan-tab-img img:first-child,
.plan-tab:hover .plan-tab-img img:first-child {
    opacity: 0;
}

.plan-tab.active .plan-tab-img img:last-child,
.plan-tab:hover .plan-tab-img img:last-child {
    opacity: 1;
}

.plan-tab h4 {
    margin: 8px 0;
    font-size: 16px;
}

.plan-tab p {
    margin: 0;
    font-size: 12px;
}

.plan-tab strong {
    font-size: 12px;
}

.plan-tab.active {
    border-left: 2px solid $greenCol;
    border-right: 2px solid $greenCol;
    background-color: $greenCol;
    color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-tab:hover {
    border-left: 2px solid $greenCol;
    border-right: 2px solid $greenCol;
    background-color: $greenCol;
    color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-cards-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px 0;
}

.plan-cards-change {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.plan-cards-change img {
    width: 18px;
    height: 18px;
    margin: 0 4px;
}

.plan-cards-change span {
    color: $darkCol;
    font-size: 12px;
}

.plan-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 18px;
    background-color: $whiteCol;
    overflow: hidden;
}

.plan-card-stretch {
    align-items: stretch;
}

.plan-card .plan-card-add {
    width: 100%;
    height: 100%;
    background-color: $whiteCol;
}

.plan-card .plan-card-add .add-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 50px 0;
    border-radius: 18px;
    border: 1px solid $greenCol;
    background-color: transparent;
}

.plan-card .plan-card-add .add-button img {
    width: 32px;
    height: 32px;
    margin-bottom: 12px;
}

.plan-card .plan-card-add .add-button h3 {
    font-size: 16px;
}

.plan-card-wrap {
    display: flex;
    flex-direction: column;
}

.plan-card .plan-card-img {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
}

.plan-card .plan-card-img img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}

.plan-card-title-wrap {
    position: relative;
}

.plan-card-title-wrap h3 img {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left: 4px;
}

.plan-card-title-wrap h3:hover~p {
    z-index: 9;
    opacity: 1;
    left: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card-title-wrap p:hover {
    z-index: 9;
    opacity: 1;
    left: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card .plan-card-info {
    display: flex;
    flex-direction: column;
    padding: 8px 1rem 1rem;
}

.plan-card .plan-card-info p {
    margin-bottom: 8px;
}

.plan-card .plan-card-info .info-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.plan-card .plan-card-img .info-mark {
    position: absolute;
    left: 1rem;
    bottom: 10px;
    z-index: 2;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: $greenCol;
    color: $grayCol;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.plan-card-info .btn-bar {
    margin-bottom: 4px;
}

.plan-card-info .btn-bar .remove-btn {
    height: 22px;
    margin-right: 8px;
    padding: 0 12px;
    border-width: 0;
    border-radius: 4px;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 12px;
    font-weight: 600;
    background-color: $pinkCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card-info .btn-bar .change-btn {
    height: 22px;
    padding: 0 12px;
    border-width: 0;
    border-radius: 4px;
    color: $darkCol;
    font-family: $title-font;
    font-size: 12px;
    font-weight: 600;
    background-color: $grayCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card-info .btn-bar button:hover,
.plan-card-info .btn-bar button:active {
    opacity: 0.85;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card-info .info-type {
    margin-bottom: 2px;
    color: $pinkCol;
    font-size: 12px;
}

.plan-card-info h3 {
    font-size: 16px;
    margin-bottom: 2px;
    cursor: pointer;
}

.plan-card-info .info-comp {
    position: absolute;
    left: 100%;
    bottom: 14px;
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    opacity: 0;
    color: $whiteCol;
    font-size: 12px;
    line-height: 14px;
    background-color: $blackCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-card-info .info-about {
    color: $greenCol;
    font-weight: 700;
}

.plan-card-info .info-status {
    color: $darkCol;
    font-size: 11px;
}

.plan-card-info .info-rate {
    margin-bottom: 2px;
}

.plan-card-info.basket-plan-selects .card-info {
    display: flex;
    grid-template-columns: 1fr 1fr;

    .card-info-length {
        width: 100%;
    }
}

@media all and (max-width: 991px) {
    .plan-card .plan-card-wrap {
        flex-direction: row;
        width: 100%;
    }

    .plan-card .plan-card-img {
        flex: 1;
        height: 100%;
    }

    .plan-card .plan-card-info {
        flex: 2;
        width: 100%;
    }
}

.card-add-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.card-add-price span {
    font-family: $title-font;
    font-size: 18px;
}

.plan-full-card {
    display: flex;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 18px;
    background-color: $whiteCol;
}

.card-info-wrap {
    flex-grow: 1;
    flex-shrink: 1;
}

.plan-card-info {
    display: flex;
}

.plan-full-card .plan-card-img img {
    width: 56px;
    height: 56px;
    margin-right: 16px;
}

.plan-full-card .card-info {
    flex-grow: 1;
    flex-shrink: 1;
}

.plan-full-card .about-program {
    font-size: 12px;
    margin-bottom: 4px;
}

.plan-full-card .card-info-status {
    padding: 2px 8px;
    border-radius: 4px;
    color: $whiteCol;
    font-size: 10px;
    font-weight: 600;
    background-color: $pinkCol;
}

.plan-full-card .card-info-title {
    margin-bottom: 0;
    margin-top: 4px;
    font-size: 24px;
}

.card-info-length {
    position: relative;
    padding-right: 48px;
    display: inline-block;
}

.card-info-length .length-list {
    position: absolute;
    top: 18px;
    left: 0;
    display: none;
    padding: 4px 0;
    border: 1px solid $darkGrayCol;
    background-color: $whiteCol;
    z-index: 10;
}

.card-info-length:hover .length-list,
.card-info-length .length-list:hover {
    display: flex;
    flex-direction: column;
}

.card-info-length .length-list a {
    padding: 4px 16px;
    cursor: pointer;
}

.card-info-text {
    font-weight: 700;
}

.card-info-text img {
    width: 9px;
    height: 9px;
    margin-left: 4px;
}

.card-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.card-price-text {
    font-family: $title-font !important;
    font-size: 16px;
    font-weight: 600;
}

.card-price-text span {
    text-decoration: line-through;
}

.card-price-text strong {
    color: $pinkCol;
    font-size: 24px;
}

.plan-calendar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 8px;
}

.plan-calendar .calendar-day {
    width: calc(100% / 7);
    padding: 0 8px 8px 0;
}

.plan-calendar .calendar-day:nth-child(7n) {
    padding-right: 0;
}

.plan-calendar .day-wrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
    border-width: 0;
    background-color: $whiteCol;
    font-family: $title-font;
    text-align: right;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-calendar .day-wrap.disabled {
    cursor: default;
    background-color: $darkGrayCol;
    color: $darkCol;
}

.plan-calendar .day-wrap.editable {
    justify-content: space-between;
    border-left: 2px solid $greenCol;
}

.plan-calendar .day-wrap:not(.disabled):hover,
.plan-calendar .day-wrap:not(.disabled).active {
    background-color: $greenCol;
    color: $whiteCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.plan-calendar .day-wrap .day-icon {
    position: relative;
    width: 18px;
    height: 18px;
}

.plan-calendar .day-wrap img {
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
}

.plan-calendar .day-wrap img:first-child {
    opacity: 0;
}

.plan-calendar .day-wrap img:last-child {
    opacity: 1;
}

.plan-calendar .day-wrap.active img:first-child,
.plan-calendar .day-wrap:hover img:first-child {
    opacity: 1;
}

.plan-calendar .day-wrap.active img:last-child,
.plan-calendar .day-wrap:hover img:last-child {
    opacity: 0;
}

.plan-day-info {
    width: 100%;
    margin: 8px 0;
    padding: 16px;
    border-radius: 8px;
    background-color: $whiteCol;
}

.plan-day-info p {
    margin: 0;
    font-weight: 700;
}

.plan-day-info p span {
    color: $pinkCol;
}

.order-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid $darkGrayCol;
}

.order-tabs .order-tab {
    position: relative;
    bottom: -1px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 30px 9px 30px;
    border-width: 0;
    border-bottom: 1px solid $darkGrayCol;
    background-color: $whiteCol;
    font-family: $title-font;
}

.order-tabs .order-tab.active {
    border-bottom: 2px solid $greenCol;
    padding: 8px 30px;
}

.order-tabs .order-tab img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

.order-tabs .order-tab span {
    color: $darkCol;
    font-weight: 600;
    text-transform: uppercase;
}

.order-tabs .order-tab.active span {
    color: $greenCol;
}

@media all and (max-width: 991px) {
    .plan-cards-list {
        flex-wrap: wrap;
    }

    .plan-calendar {
        width: 100%;
    }

    .cards-list {
        width: 100%;
    }

    .profile-menu {
        display: none;
    }
}

@media all and (max-width: 768px) {
    .plan-calendar .day-wrap .day-icon {
        display: none;
    }
}

@media all and (max-width: 640px) {
    .plan-full-card {
        flex-direction: column;
    }

    .card-price {
        align-items: flex-start;
        margin-left: 0;
        margin-bottom: 8px;
    }
}

@media all and (max-width: 512px) {
    .plan-card .plan-card-img {
        width: 0;
    }

    .plan-calendar .day-wrap {
        text-align: center;
    }

    .plan-calendar .day-wrap {
        padding: 6px;
    }

    .plan-calendar .calendar-day {
        padding: 0 2px 8px 0;
    }

    .plan-full-card {
        padding: 1rem;
    }
}

@media all and (max-width: 480px) {
    .order-tabs {
        flex-direction: column;
    }
}

@media all and (max-width: 370px) {
    .plan-calendar .day-wrap {
        justify-content: center !important;
        padding: 10px 0;
        text-align: center;
    }

    .plan-calendar .day-wrap strong {
        font-size: 11px;
    }
}

@media all and (max-width: 354px) {
    .card {
        padding: 35px 0;
    }

    .plan-calendar .day-wrap {
        padding: 4px;
    }

    .order-tabs .order-tab {
        padding-left: 8px;
        padding-right: 8px;
    }

    .order-tabs .order-tab.active {
        padding: 8px;
    }
}

.cards-list {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 4px 0;
}

.delivery-wrap {
    padding: 1rem;
    background-color: $whiteCol;
}

.delivery-wrap h3 {
    font-size: 18px;
}

.delivery-wrap h3 img {
    width: 16px;
    height: 16px;
    margin-left: 6px;
    margin-bottom: 2px;
}

.delivery-first {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
    padding: 0;
    border-width: 0;
    background-color: transparent;
}

.delivery-first .select-list {
    position: absolute;
    left: 6px;
    top: 40px;
    z-index: 8;
    width: calc(100% - 12px);
    border-bottom: 1px solid $darkGrayCol;
    border-top: 1px solid $darkGrayCol;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-transition: max-height 0.35s ease-out 0s;
    -moz-transition: max-height 0.35s ease-out 0s;
    -o-transition: max-height 0.35s ease-out 0s;
    transition: max-height 0.35s ease-out 0s;
}

.delivery-first .select-list.active {
    max-height: 144px;
    opacity: 1;
    -webkit-transition: max-height 0.35s ease-out 0s;
    -moz-transition: max-height 0.35s ease-out 0s;
    -o-transition: max-height 0.35s ease-out 0s;
    transition: max-height 0.35s ease-out 0s;
}

.delivery-first .select-list::-webkit-scrollbar {
    width: 8px;
    background-color: $darkGrayCol;
}

.delivery-first .select-list::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: $greenCol;
}

.delivery-first .select-list .select-list-item {
    position: relative;
    width: 100%;
    margin-top: -1px;
    padding: 8px 16px;
    border: 1px solid $darkGrayCol;
    background-color: $whiteCol;
    color: $darkCol;
    font-family: $title-font;
}

.delivery-first .select-list .select-list-item.active {
    background-color: $darkGrayCol;
}

.delivery-first .select-list .selected-list-item {
    color: rgba(95, 185, 97, 1);
}

.delivery-first .select-list .selected-list-item:before {
    content: "X";
    color: red;
    margin-right: 6px;
}

.delivery-first .select-list .select-list-item:last-child {
    border-bottom: 0;
}

.delivery-first .delivery-first-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid $greenCol;
}

.delivery-first input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid $greenCol;
}

.delivery-first p {
    margin: 0;
}

.delivery-first img {
    width: 9px;
    height: 9px;
    margin-left: 12px;
}

.delivery-item {
    position: relative;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid $darkGrayCol;
    border-top: 1px solid $darkGrayCol;
}

.delivery-item .item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.delivery-item p {
    margin: 0;
    color: $darkCol;
}

.delivery-item img {
    width: 9px;
    height: 9px;
}

.delivery-item .item-info {
    width: 100%;
    padding: 1rem 0;
}

.delivery-item .item-info p span {
    color: $greenCol;
    font-size: 15px;
    font-weight: 700;
}

.delivery-item .item-info h3 {
    font-size: 16px;
}

.delivery-item .item-info .delivery-btn {
    padding: 4px 8px;
    margin-top: 8px;
    border-radius: 4px;
    border-width: 0;
    background-color: $greenCol;
    color: $whiteCol;
    font-family: $title-font;
    font-size: 12px;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.delivery-item .item-info .delivery-btn:hover,
.delivery-item .item-info .delivery-btn:active {
    opacity: 0.85;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

/*========================= 
  Modals 
  =========================*/

.modal-wrap.show {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 2rem;
    overflow-y: auto;
    background-color: $grayCol;
}

@media all and (max-width: 991px) {
    .modal-wrap.show {
        padding: 0;
    }
}

.modal-wrap.hide {
    left: 100%;
    z-index: 0;
    width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
}

.modal-wrap.show~.wrapper {
    height: 100%;
    overflow: hidden;
}

.modal-wrap .modal-card {
    padding: 24px;
}

.card-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.card-title h3 {
    font-size: 18px;
}

.card-title .card-title-btn {
    width: 36px;
    height: 36px;
    border-width: 0;
    background-color: transparent;
}

.card-title .card-title-btn img {
    width: 16px;
    height: 16px;
}

.modal-payment {
    position: absolute;
    left: calc(50% - 300px);
    width: 100%;
    max-width: 600px;
    padding: 1rem 2rem;
    border-radius: 24px;
    background-color: $whiteCol;
}

.modal-payment .card-title {
    margin: 0;
}

.btn-payment-wrap {
    margin-top: 8px;
}

.modal-payment .payment-wrap {
    width: 100%;
}

.modal-payment .program-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid $darkGrayCol;
}

.modal-payment .program-info img {
    flex-grow: 0;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 16px;
}

.modal-payment .program-info-title {
    flex-grow: 1;
    flex-shrink: 1;
}

.modal-payment .program-info-title h3 {
    margin-bottom: 0;
    font-size: 18px;
}

.modal-payment .program-info-title p {
    margin: 0;
}

.modal-payment .program-info-price {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    padding-left: 12px;
}

.modal-payment .program-info-price p {
    margin: 0;
    color: $greenCol;
    font-family: $title-font !important;
    font-size: 20px;
    font-weight: 600;
}

.promo-info-date {
    position: relative;
    z-index: 999;

    div {
        display: block;

        .react-datepicker {
            display: inline-block;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            display: inline-block;
        }
    }

    &.modal-transfer {
        z-index: 1001;

        .card-info-length {
            margin-bottom: 16px;
            padding-right: 0;

            .select-wrap {
                display: flex;

                .input-group {
                    display: flex;

                    .select-input {
                        margin: 0 4px;
                    }

                    .btn-arrow {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.modal-transfer {
    .payment-type {
        .btn-payment-wrap {
            width: 100%;
            margin-top: 24px;

            .green-button {
                width: 100%;
            }
        }
    }
}

.modal-payment.modal-transfer {
    margin-top: 40px;
}

.modal-payment .bonus-info h3 {
    font-size: 16px;
}

.modal-payment .pay-sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
}

.modal-payment .pay-sum span {
    font-weight: 700;
}

.modal-payment .pay-sum h3 {
    color: $greenCol;
    font-size: 20px;
}

.modal-payment .payment-type {
    width: 320px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.modal-payment .payment-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid $darkGrayCol;
    border-radius: 4px;
    background-color: $whiteCol;
}

.modal-payment .payment-item.active {
    border-color: $greenCol;
}

.modal-payment .card-wrap {
    display: none;
    width: 100%;
    margin-bottom: 8px;
    padding: 1rem;
    padding-top: calc(1rem + 8px);
    border: 1px solid $greenCol;
    border-radius: 4px;
    background-color: $grayCol;
}

.modal-payment .card-wrap .card-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
}

.modal-payment .card-wrap .card-row .card-input {
    width: 100%;
    height: 32px;
    padding: 0 16px;
    border-radius: 4px;
    border-width: 0;
    color: $darkCol;
    font-size: 14px;
}

.modal-payment .card-wrap .card-input-50 {
    width: 50%;
}

.modal-payment .pay-wrap {
    display: flex;
    align-items: center;
    padding: 6px 12px;
}

.modal-payment .pay-wrap img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.modal-payment .pay-wrap p {
    margin: 0;
}

.modal-payment .check-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 12px;
}

.modal-payment .check-wrap img {
    width: 22px;
    height: 22px;
    margin-right: 3px;
}

.modal-payment .check-wrap .check {
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: 8px;
    border-radius: 9px;
    border: 1px solid $darkGrayCol;
}

.modal-payment .check-wrap .check::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $whiteCol;
}

.modal-payment .check-wrap .check.active::after {
    background-color: $greenCol;
}

@media all and (max-width: 640px) {
    .modal-payment {
        left: 0;
    }
}

@media all and (max-width: 540px) {
    .plan-card .plan-card-wrap {
        flex-direction: column;
    }

    .plan-card .plan-card-img {
        flex: auto;
        height: 150px;
        width: 100%;
    }

    .plan-card .plan-card-info {
        flex: auto;
    }
}

@media all and (max-width: 420px) {
    .modal-payment .payment-type {
        width: 100%;
    }

    .modal-payment {
        padding: 1rem;
    }
}

/*=========================
  Contacts & Footer 
  =========================*/

.contacts {
    position: relative;
    padding: 0;
    color: $whiteCol;
    background-color: $blackCol;
}

.contacts-wrap {
    width: 100%;
    padding: 50px 0;
    background-image: url(../assets/imgs/section/footer-bg.png);
    background-size: cover;
}

.contacts .logo {
    margin: 0 0 20px 0;
}

.contacts .logo .footer-logo {
    width: 60px;
}

.contacts h4 {
    font-size: 16px;
}

.contacts .social-wrap {
    display: flex;
    align-items: center;
}

.contacts .social-wrap .btn-icon img {
    width: 24px;
    height: 24px;
}

/* footer */

footer {
    position: relative;
    width: 100%;
    padding: 16px 0;
    background-color: rgba(0, 0, 0, 0.25);
}

footer p {
    margin: 0;
    color: $whiteCol;
}

footer p span {
    color: $greenCol;
}

.top-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999;
    width: 56px;
    height: 56px;
    border-radius: 45px;
    border-width: 0;
    background-color: $whiteCol;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.top-button.show {
    display: block;
}

.top-button.hide {
    display: none;
}

.top-button img {
    width: 28px;
    height: 28px;
}

@media all and (max-width: 991px) {
    .top-button {
        bottom: 16px;
        right: 16px;
    }
}

.map-wrap {
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

/*=========================
  Profile & Subscribe 
  =========================*/

.sub-wrap {
    width: 100%;
}

.sub-info-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $whiteCol;
}

.sub-info-card .sub-info-img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}

.sub-info-card img {
    width: 20px;
    height: 20px;
}

.sub-info-card p {
    margin-bottom: 0;
    color: $blackCol;
    font-size: 13px;
    text-align: left;
}

@media all and (max-width: 991px) {
    .about-info-card {
        margin-bottom: 8px;
        padding: 8px 0;
    }

    .about-info-card:hover .about-info-back-bg {
        display: none;
    }

    .about-info-card:hover h4 {
        color: $blackCol;
    }

    .about-info-card:hover p {
        color: $blackCol;
    }

    .about-info-card:hover img.icon {
        opacity: 1;
    }

    .about-info-card:hover img.icon-white {
        opacity: 0;
    }

    .about-info-card:hover h4::after {
        background-color: $greenCol;
    }

    .about-info-card p {
        margin-bottom: 8px;
    }
}

.sub-activiti {
    width: 100%;
}

.sub-card-info {
    width: max-content;
    padding: 8px;
    margin-top: 8px;
    display: flex;
    flex-flow: wrap;
    border: 1px solid $greenCol;
    border-radius: 12px;
    background-color: $whiteCol;
}

.sub-card-info p {
    color: $darkCol;
    margin-left: 8px;
}

.sub-header-button {
    margin: 8px 0;
}

.sub-card {
    padding-top: 24px;
    padding-bottom: 16px;
}

.sub-help {
    position: relative;
    width: 100%;
    padding: 4px;
}

.sub-help-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
    width: 100%;
}

.sub-help-btn {
    flex-grow: 0;
    flex-shrink: 0;
    width: 300px;
}

.sub-help-info {
    position: absolute;
    top: 0;
    right: 0;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(100% - 300px);
    padding: 8px 8px 8px 24px;
}

@media all and (max-width: 991px) {
    .sub-help-btn {
        width: 100%;
    }

    .sub-help-info {
        position: static;
        width: 100%;
        padding-left: 8px;
    }
}

.profile-menu-subscribe {
    width: 100%;
}

.menu-subscribe-item {
    position: relative;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #5fb961;
    border-radius: 8px;
    background-color: transparent;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.menu-subscribe-item.active::after {
    content: "";
    position: absolute;
    left: calc(100% + 2px);
    top: calc(50% - 6px);
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid $greenCol;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

@media all and (max-width: 991px) {
    .profile-menu-subscribe .menu-subscribe-item.active::after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $greenCol;
    }
}

.profile-menu-subscribe .menu-subscribe-item p {
    margin: 0;
    font-size: 13px;
    text-align: left;
}

.profile-menu-subscribe .menu-subscribe-item.active {
    background-color: $greenCol;
    -webkit-transition: all 0.35s ease-out 0s;
    -moz-transition: all 0.35s ease-out 0s;
    -o-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.profile-menu-subscribe .menu-subscribe-item.active p {
    color: $whiteCol;
}

/*=========================
  Profile & Bonus 
  =========================*/
.bonus {
    width: 100%;
}

.bonus p {
    margin: 0;
}

.bonus-wrap {
    width: 100%;
    background-color: #fff;
    padding: 24px;
    border-radius: 25px;
    overflow: hidden;
    overflow-x: scroll;
}

.bonus-header {
    font-size: 2em;
    margin-bottom: 16px;
}

.bonus-block-converting {
    display: flex;
    border: 1px solid;
    border-radius: 25px;
    margin-bottom: 16px;
    padding: 8px 20px;
    border-color: #5fb961;
    justify-content: space-between;
}

.bonus-block-converting-right {
    display: flex;
    align-items: center;
    font-size: 2em;
}

.bonus-block-converting-right img {
    width: 24px;
    height: 24px;
    margin: 0 8px;
}

.bonus-sub-header {
    font-size: 1em;
    font-weight: bold;
    margin: 16px 0;
}

.bonus-link-section {}

.bonus-link-block {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    margin-bottom: 8px;
}

.bonus-link-group {
    text-align: start;
}

.bonus-input {
    height: auto;
    min-height: 36px;
    width: -webkit-fill-available;
    padding: 4px 8px;
    border-color: $greenCol;
    border-style: solid;
    border-width: 2px;
    border-radius: 25px !important;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.form-group-info-btn-wrap {
    position: relative;
    padding-right: 120px;
}

.form-group-info-btn {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2;

    .btn.btn-sm {
        padding: 4px 32px;
    }
}

@media all and (max-width: 480px) {
    .bonus-input p {
        font-size: 12px;
    }
}

.bonus-button {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 16px;
}

@media all and (max-width: 576px) {
    .bonus-link-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 16px;
    }

    .bonus-button {
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 16px;
    }

    .bonus-link-group {
        text-align: center;
    }
}

.bonus-border {
    color: $darkCol;
    margin-bottom: 24px;
}

.bonus-footer {
    display: flex;
    color: darkgray;
    border-top: 1px solid gray;
}

.bonus-footer strong {
    font-size: 1.2em;
    color: black;
}

.bonus-footer-left {
    padding: 8px;
    border-right: 1px solid gray;
    width: 50%;
    text-align: center;
}

.bonus-footer-right {
    padding: 8px;
    width: 50%;
    text-align: center;
}

/* basket-plan-full-card */
.basket-plan-full-card {
    margin-bottom: 8px;

    .plan-card-info {

        //margin-bottom: 20px;
        .card-info {
            .card-info-length {
                margin-top: 8px;
                margin-bottom: 28px;
            }
        }
    }

    p {
        //margin-top: 20px;

        label {
            font-size: 15px;
            font-weight: 700;
        }
    }

    label {
        font-size: 15px;
        font-weight: 700;
    }

    .card-price {
        .card-price-text {
            margin-bottom: 8px;
        }
    }
}

.reviews-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        position: absolute;
        top: calc(50% - 32px);
        border: 0;
        background-color: transparent;

        &.reviews-slider-prev {
            left: 0;
        }

        &.reviews-slider-next {
            right: 0;
        }

        .reviews-slider-icon {
            width: 32px;
            height: 32px;
        }
    }

    .reviews-slider-slide {
        position: absolute;
        opacity: 0;
        max-width: 900px;
        transition: all 0s ease-out 0s;

        &.active {
            position: relative;
            opacity: 1;
            transition: all 0.65s ease-out 0s;
        }

        p {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;

            span {
                color: #ccc;
                font-weight: 600;
            }
        }
    }
}